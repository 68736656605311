import { render, staticRenderFns } from "./ClassroomAttendance.vue?vue&type=template&id=f5c0670c&scoped=true"
import script from "./ClassroomAttendance.vue?vue&type=script&lang=js"
export * from "./ClassroomAttendance.vue?vue&type=script&lang=js"
import style0 from "./ClassroomAttendance.vue?vue&type=style&index=0&id=f5c0670c&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5c0670c",
  null
  
)

export default component.exports